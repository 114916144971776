"use client";
import React from "react";
import { WebRoutes } from "@/src/routes";
import Image from "next/image";
import { Button } from "antd";
import { useRouter } from "next/navigation";
import "@/src/app/(website)/globals.scss";

import imgNotFound from "@/src/public/img/404_error.png";

const NotFound = () => {
  const navigate = useRouter();
  return (
    <section className="bg-white min-h-screen flex items-center">
      <div className="py-8 mx-auto">
        <div className="mx-auto max-w-screen-sm text-center">
          <Image src={imgNotFound} alt="Not Found" className="w-10/12 mx-auto mb-8" />
          <p className="text-3xl font-bold text-gray-900 md:text-4xl">Something&apos;s missing.</p>
          <p className="text-lg font-light text-gray-500 dark:text-gray-400 mt-2 mb-6">Sorry, we can&apos;t find that page. You&apos;ll find lots to explore on the homepage.</p>
          <Button onClick={() => navigate.push(WebRoutes.HOME)} type="primary">
            Back to Home
          </Button>
        </div>
      </div>
    </section>
  );
};

export default NotFound;
